.container {
  min-width: 800px;
  position: relative;
}

.language-selector {
  position: absolute;
  top: 10px;
  right: 20px;
  color: white;
  cursor: pointer;
}

.page-title {
  background-color: #043a4c;
  color: white;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
  padding: 50px 0;

  .subtitle {
    font-weight: normal;
    font-size: 40px;
  }
}

.block-container {
  margin: 0 auto;
  max-width: 800px;
}

.page {
  margin: 0 auto 20px auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block {
  .header {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }

  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  padding: 30px;

  &.intro {
    margin-top: 50px;
  }

  .paragraph {
    margin-top: 18px
  }

  &.with-background {
    background-color: #043a4c;
    color: white;
    margin-top: 30px;
  }
}

.download-button {
  padding: 15px 25px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  background-color: #3CA4C3;
  margin: 20px 0;
  border-radius: 5px;

  svg {
    width: 25px;
    margin-right: 10px;
  }
}

.pictures {
  display: flex;
  flex-direction: column;
  align-items: center;

  .row {
    margin: 15px 0;
    display: flex;
  }

  .picture {
    margin: 0 10px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    position: relative;

    .text {
      color: white;
      z-index: 2;
      height: inherit;
      width: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 50%;
      opacity: 0;
      background-color: rgba(4, 59, 76, 0.7);
      transition: opacity 500ms;
      -webkit-transition: opacity 500ms;
      -moz-transition: opacity 500ms;
      -ms-transition: opacity 500ms;
      -o-transition: opacity 500ms;
      white-space: pre;
    }

    &:hover {
      .text {
        opacity: 1;
      }
    }

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .background-layer {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 50%;
      background-color: rgba(4, 59, 76, 0.1);
    }
  }
}

.footer {
  background-color: #043a4c;
  color: white;
  padding-bottom: 50px;
  padding-top: 5px;
  text-align: center;

  .block {
    margin-bottom: 10px;
    text-align: center;
  }
}

.contact-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    width: 50px;
    text-decoration: none;
    color: white;
  }

  a {
    padding: 10px;
  }

  .github-link {
    margin-top: 30px;
  }
}

.graphs {
  background-color: #f1f1f1;
  border: 1px solid #c0c3c3;
  margin-top: 30px;

  .selector {
    display: flex;
    cursor: pointer;
    background-color: rgba(4, 59, 76, 0.3);


    .select-button {
      padding: 8px 0;
      flex: 1;
      text-align: center;

      &.selected {
        font-weight: bold;
        background-color: #043a4c;
        color: white;
      }
    }
  }

  .explanation-container {
    border-top: 1px solid #c0c3c3;

    .show-explanation-button {
      cursor: pointer;
      padding: 5px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .explanation {
      padding: 10px 30px 20px 30px;
      text-align: justify;
    }
  }
}

.expand-icon {
  width: 30px;
  color: #404141;
}

.how-to-read {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;

  .question-icon {
    width: 40px;
    color: #043a4c;
    cursor: pointer;
  }

  .close-icon {
    width: 24px;
    height: 24px;
    color: #043a4c;
    cursor: pointer;
  }

  .pop-up-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.5);

    .pop-up {
      margin: auto;
      margin-top: 100px;
      width: 700px;
      background-color: white;
      padding: 20px;
      border: 1px solid #043a4c;
      border-radius: 10px;
      color: #043a4c;
      text-align: justify;

      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
      }

      .paragraph {
        margin-top: 10px;
      }
    }
  }
}

.legend {
  position: absolute;
  border: 1px solid #c0c3c3;
  background-color: white;
  font-size: 14px;

  .legend-title {
    font-size: 18px;
    padding: 5px 10px;
  }

  .content {
    padding: 8px;
    border-top: 1px solid #c0c3c3;
    white-space: pre;
  }

  .circle {
    padding: 8px;
    border-radius: 50%;
    color: white;
    display: inline-block;
  }

  td {
    padding: 2px;
  }
}